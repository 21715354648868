const data = [
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/1.png",
    text: "1",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/2.png",
    text: "2",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/3.png",
    text: "3",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/4.png",
    text: "4",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/5.png",
    text: "5",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/6.png",
    text: "6",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/7.png",
    text: "7",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/8.png",
    text: "8",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/9.png",
    text: "9",
  },
  {
    src:
      "https://archdesignsstudio.com/assets/projects-re/mothersoncorporatepark/10.png",
    text: "10",
  },
];

export default data;
