import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Motherson Corporate Park, Noida",
  address: "Noida, Uttra Pradesh",
  client: "",
  area: "8.0 Acres",
  project: "Motherson Corporate Park",
  category: "Landscape | Architecture",
  status: "Completed",
  backlink: "/projects/landscape/",
};

export default class Projectitcmothersoncorporateparknoida extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Landscape Projects | Motherson Corporate Park, Noida"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="View the lush landscape and outdoor layout designed for an industrial park and commercial space spread over 8 acres."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
